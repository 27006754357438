import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Address } from '../address';
import { SharedService } from "../shared.service";
import { Airorder } from '../airorder';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'add-inline',
  templateUrl: './add-inline.component.html',
  styleUrls: ['./add-inline.component.css']
})
export class AddInlineComponent implements OnInit {
  @Input() form;
  @Input() dest: boolean;
  @Output() newAdd = new EventEmitter();
  constructor(private route: ActivatedRoute, private sharedService: SharedService, private ref: ChangeDetectorRef, private Elm: ElementRef) { }

  ngOnInit(): void {
  }
  selectedPlace: any;
  options = {
    componentRestrictions: {
      country: 'ca'
    }
  }
  addFocus() {
    // this.addFoo.emit(true);
    // this.hide=true;
    // window.scroll(0,0);
  }
  addFocusout() {
    // this.hide=false;
    // this.addFoo.emit(false);
  }
  placeChanged(place) {
    this.selectedPlace = place;
    this.ref.detectChanges();
    let num = '';
    let add = '';
    this.selectedPlace.address_components.forEach(element => {
      // if (element.types.includes("street_number")) {
      //   num = element.short_name;
      // } else if (element.types.includes("route")) {
      //   add = element.short_name;
      // } else if (element.types.includes("administrative_area_level_1")) {
      //   this.form.province = element.short_name;
      // } else if (element.types.includes("locality")) {
      //   this.form.city = element.short_name;
      // }

    });
    // if (num == '' || add == '') this.form.address = this.selectedPlace.name;
    // else this.form.address = num + " " + add;

    this.selectedPlace.address_components.forEach(element => {
      if (element.types.includes("sublocality") && element.short_name == "North York") {
        // this.form.city = element.short_name;
      }
    });
  }

}
