<app-head [bk]="nav.bk" [title]="title" ></app-head>
<!-- {{nav|json}} -->
<div class="container ">
    <div class="content p-3">
        <form #form="ngForm" (ngSubmit)="submit()">
            <div class="row justify-content-around mb-4">
                <div class="col-4 pl-0 pr-1">
                    <div class="field"><select [(ngModel)]="user.cc" class=" custom-select bg-white" name="cc">
                            <option value=0 disabled>国家区号</option>
                            <option value=1>+1 加/美</option>
                            <option value=86>+86 中国</option>
                        </select></div>
                </div>
                <div class="col-8 pl-1 pr-0">
                    <div class="field"><input [(ngModel)]="user.tel" name="tel" type="tel" placeholder="手机号码"></div>
                </div>
            </div>
            <div class="row justify-content-around">
                <div class="col-8 pl-0 pr-1">
                    <div class="field"><input [(ngModel)]="user.code" name="code" type="tel" placeholder="验证码"></div>
                </div>
                <button class="btn col-4 field pl-1 pr-0" [disabled]="disableSend || user.cc==0 ||(user.tel==null || user.tel<1000000000)" (click)="sendCode()">{{sendBtn}}</button>
            </div>
            <button [disabled]="user.cc==0 ||(user.tel==null || user.tel<1000000000)|| (((user.code <=100000) || (user.code >=999999))||user.code==null)" class="btn field button mt-5" type="submit">验证</button>
        </form>
        
    </div>
    
</div>
<app-modal [modal]="modal"></app-modal>
<app-loading [hidden]="loadingHidden"></app-loading>
<!-- {{user|json}}
 -->
<!-- {{nav|json}} -->