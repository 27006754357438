import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import {HttpClientModule} from '@angular/common/http';



import { AppRoutingModule } from './app-routing.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { AppComponent } from './app.component';

import { DragScrollModule } from 'ngx-drag-scroll';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CarouselComponent } from './carousel/carousel.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { TourComponent } from './tour/tour.component';
import { BookComponent } from './book/book.component';
import { PriceComponent } from './price/price.component';
import { MyComponent } from './my/my.component';
import { FormAirComponent } from './form-air/form-air.component';
import { FormRideComponent } from './form-ride/form-ride.component';
import { FormTourComponent } from './form-tour/form-tour.component';
import { FlightComponent } from './flight/flight.component';
import { LoginComponent } from './login/login.component';
import { PsgInputComponent } from './psg-input/psg-input.component';
import { AirBriefComponent } from './air-brief/air-brief.component';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { LoadingComponent } from './loading/loading.component';
import { HeadComponent } from './head/head.component';
import { CarBriefComponent } from './car-brief/car-brief.component';
import { AddBriefComponent } from './add-brief/add-brief.component';
import { AddInputComponent } from './add-input/add-input.component';
import { CommInputComponent } from './comm-input/comm-input.component';

import { NgxAutocomPlaceModule } from 'ngx-autocom-place';
import { RideComponent } from './ride/ride.component';
import { RideBriefComponent } from './ride-brief/ride-brief.component';
import { CarSelectComponent } from './car-select/car-select.component';
import { AddInlineComponent } from './add-inline/add-inline.component';
import { AddListComponent } from './add-list/add-list.component';

@NgModule({
  declarations: [
    AppComponent,
    // BsDatepickerModule,
    CarouselComponent,
    NavbarComponent,
    AboutComponent,
    HomeComponent,
    TourComponent,
    BookComponent,
    PriceComponent,
    MyComponent,
    FormAirComponent,
    FormRideComponent,
    FormTourComponent,
    FlightComponent,
    LoginComponent,
    PsgInputComponent,
    AirBriefComponent,
    AlertComponent,
    ModalComponent,
    LoadingComponent,
    HeadComponent,
    CarBriefComponent,
    AddBriefComponent,
    AddInputComponent,
    CommInputComponent,
    RideComponent,
    RideBriefComponent,
    CarSelectComponent,
    AddInlineComponent,
    AddListComponent,
    

  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    RatingModule.forRoot(),
    HttpClientModule,
   
    NgxAutocomPlaceModule,
    DragScrollModule
  ],
  providers: [ 
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
