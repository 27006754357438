import { Address } from "./address";
export class Rideorder {
    date: string = '';
    time: string = '';
    timehour: string = '';
    timemin: string = '';
    share: boolean = false;
    orig: string = '';
    dest: string = '';
    origList: Array<Address> = [];
    destList: Array<Address> = [];
    price: number = 0;
    driver: number = 0;
    car: number = 0;
    contact_name: string = '';
    contact_tel: number = 0;
    person: number = 0;
    luggage_large: number = 0;
    luggage_small: number = 0;
    user_comm: string = '';
}
