<app-head [bk]="nav.bk" [title]="title" [keepUrl]="nav.keepUrl" [step]="nav.step" (backstep)="back()"></app-head>
{{rideOrder|json}}<br>
<!-- {{nav|json}}<br> -->
<!-- {{addForm|json}}<br>
{{user|json}} -->
<ride-brief [rideOrder]="rideOrder"></ride-brief>

<div id="orig_brief" *ngIf="(nav.step >= 1) && (rideOrder.origList.length >0)" class="mt-3 mb-3">
    <div class="brief_title ml-2">上车信息:</div>
    <div class="brief">
        <add-brief [addList]="rideOrder.origList" (changeIndex)="changeOrig($event)" ></add-brief>
    </div>
    
</div>
<div id="dest_brief" *ngIf="(nav.step >= 1) && (rideOrder.destList.length >0)" class="mt-3 mb-3">
    <div class="brief_title ml-2">下车信息:</div>
    <div class="brief">
        <add-brief [addList]="rideOrder.destList" [dest]="true" (changeIndex)="changeDest($event)" ></add-brief>
    </div>
</div>

<div id="car" *ngIf="nav.step == 0">
    <car-select [color]="'ride'" [car_select]="carSelect" (selectCar)="setCar($event)"></car-select>
    <div class="form-group mt-5">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-9 ">
                <button [disabled]="rideOrder.car==0" type="submit" class="btn shadow" (click)="next()">下一步</button>
            </div>

            <div class="col-1"></div>
        </div>
    </div>
</div>

<div id="orig_address" *ngIf="nav.step == 1" class="ml-4 mr-4">
    <add-input [dest]="false" [(form)]="addForm" (newAdd)="newOrig($event)" ></add-input>
</div>
<div id="next_btn" class="ml-4 mr-4" *ngIf="(nav.step == 1)&&(addForm.p_left.length == 0)&& (rideOrder.destList.length == 0)">
    <button class="btn mt-5" (click)="next()">下一步 添加下车地址</button>
</div>

<div id="dest_address" class="ml-4 mr-4" *ngIf="nav.step == 2">
    <add-input [dest]="true" [(form)]="addForm" (newAdd)="newDest($event)"></add-input>
    <button *ngIf="rideOrder.destList.length > 0" class="btn mt-2 mb-3" (click)="next()">下一步</button>
</div>
<div id="next_btn" *ngIf="(nav.step == 3)">
    <comm-input [share]="false" [ride]="true" ></comm-input>
    <!-- <button class="btn mt-5" (click)="next()">下一步</button> -->
</div>
<button  class="btn mt-2 mb-3" (click)="checkPrice()">Check Price</button>
<div class="mt-5 mb-5" style="height: 30px;"> </div>
<app-navbar [page]="2"></app-navbar>
<app-modal [modal]="modal"></app-modal>
<app-loading [hidden]="loadingHidden"></app-loading>