import { Injectable,OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {UserData} from "./user-data";
import {Flight} from "./flight";
import {Airorder} from "./airorder";
import { Rideorder } from "./rideorder";
import { Const } from './const';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  dft_form= Const.DEFAULTADDFORM;
  private page = new BehaviorSubject(0);
  currentPage = this.page.asObservable();

  private loading = new BehaviorSubject(false);
  currentLoading = this.loading.asObservable;

  // private user_data = new BehaviorSubject(new UserData());
  // currentUserData = this.user_data.asObservable();

  private airOrder = new BehaviorSubject(new Airorder());
  currentAirOrder = this.airOrder.asObservable();

  private rideOrder = new BehaviorSubject(new Rideorder());
  currentRideOrder = this.rideOrder.asObservable();

  private flight = new BehaviorSubject(new Flight());
  currentFlight = this.flight.asObservable();

  private modal = new BehaviorSubject({show:false,title:'',body:''});
  currentModal = this.modal.asObservable();

  private countDownTimer = new BehaviorSubject(120);
  currentTimer = this.countDownTimer.asObservable();

  // private addFormValueIndex = new BehaviorSubject(Number);
  // currentAddFormValue = this.addFormValueIndex.asObservable();
  
  private addFormValue = new BehaviorSubject(this.dft_form);
  currentAddFormValue = this.addFormValue.asObservable();

  
  constructor() { }
  public tel2cc(tel:string){//tel start with 1 or 86
    let cc="1";
    if (tel.charAt(0)=="1") {
      tel =tel.substr(1);
    }else{
      cc=tel.substr(0,2);
      tel = tel.substr(2);
    } 
    return [cc,tel];
  }
  public updAddFormValue(form_value){
    this.addFormValue.next(form_value); 
  }
  public resetAddFormValue(){
    this.addFormValue.next(this.dft_form); 
    alert(JSON.stringify(this.addFormValue));
  }
  public updPage(page: number) {
    this.page.next(page)
  }
  // public updUserDate(data: UserData){
  //   this.user_data.next(data)
  // }
  public updFlight(data:Flight){
    this.flight.next(data)
  }

  public updAir(air:Airorder){
    this.airOrder.next(air);
  }
  public updRide(ride: Rideorder) {
    this.rideOrder.next(ride);
  }

  setSession(user,data){
    localStorage.setItem(user,data);
    this.updSeesionTime();
  }
  getSession(key){
    var now = new Date();
    if ((now.getTime()-parseInt(localStorage.getItem('time')))<35*60*1000) {
      return localStorage.getItem(key);
    } else {
      localStorage.removeItem(key);
      return localStorage.getItem(key);
    }
  }
  delSession(user){
    localStorage.removeItem(user);
  }
  updSeesionTime(){
    var time = new Date();
    localStorage.setItem('time',time.getTime().toString());
  }
  updLoading(b:boolean){
    this.loading.next(b);
  }
  updModal(modal){
    localStorage.setItem('modal',JSON.stringify(modal));
  }
  resetModal(modal){
    localStorage.setItem('modal',JSON.stringify({show:false,title:'',body:''}));
  }
  getModal(){
    // alert((localStorage.getItem('modal')));
    return JSON.parse(localStorage.getItem('modal'));
  }
  public cap(str:string){
    let s = str.split(" ");
    let r='';
    for (let index = 0; index < s.length; index++) {
      r+=s[index].charAt(0).toUpperCase()+s[index].slice(1).toLowerCase()+" ";
      
    }
    return r.substr(0,r.length-1);
  }
  public toMMDD(m:string){
    if (m.length==1) {
      return '0'+m;
    }else return m;
  }

}
