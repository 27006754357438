<div class="container bg-white text-black  pt-2 pb-2">
    <div class="brief row">
        <div class="col-9 text-center mt-2">
            <div class="row mb-1">
                <div class="col-3 font-grey ">出发时间</div>
                <div class="col-5 info">{{rideOrder.date}}</div>
                <div class="col-4 info">{{rideOrder.time}}</div>
            </div>
            <div class="row mb-1">
                <div class="col-3 font-grey">乘车人数</div>
                <div class="col-5 info">{{rideOrder.person}}人</div>
                <div class="col-4 info">专车</div>
            </div>
        </div>
        <div class="col-3 text-center " >
            <h3 class="mt-1 price" *ngIf="rideOrder.price >0">${{rideOrder.price}}</h3>
            <div class="font-grey p-0" *ngIf="rideOrder.price >0"><u>价格及乘车说明</u></div>
        </div>

    </div>
</div>

